<!-- eslint-disable no-restricted-syntax -->
<template>
  <div class="row" style="margin-bottom: 0">
    <div class="card col s12 padding-0 main-card" style="margin-bottom: 0; position: relative">
      <div>
        <p class="title"><span class="material-icons">assignment</span>Create Assignment</p>
      </div>
      <div class="col s12 body-for-mobile padding-0 height-100">
        <div class="col s12 l3 margin-0 height-100" style="background-color: #fff">
          <p class="widgets-icon" @click="isMobileNavigationVisible = true">
            <span class="material-icons">widgets</span>
          </p>
          <div
            class="flex flex-column items-end nav-section-mobile"
            :class="{ 'active-nav': isMobileNavigationVisible }"
          >
            <div style="position: relative; width: 100%" v-if="isMobileNavigationVisible">
              <p class="close-nav-icon" @click="isMobileNavigationVisible = false">
                <span class="material-icons">close</span>
              </p>
            </div>
            <div
              class="content-selection-button flex flex-column"
              :class="{ active: selectedSection === 1 }"
              @click="viewCourse()"
            >
              <span class="fs-14 fw-600">Course</span>
              <span class="fw-400 fs-12 blk-text">{{ selectedCourseName }}</span>
            </div>
            <div
              class="content-selection-button flex flex-column"
              :class="{ active: selectedSection === 2 }"
              @click="viewClass()"
            >
              <span class="fs-14 fw-600">Class</span>
              <span class="fw-400 fs-12 blk-text">{{ selectedClassName }}</span>
            </div>
            <div
              class="content-selection-button flex flex-column"
              :class="{ active: selectedSection === 3 }"
              @click="viewSubject()"
            >
              <span class="fs-14 fw-600">Subject</span>
              <span class="fw-400 fs-12 blk-text">{{ selectedSubjectName }}</span>
            </div>
            <div
              class="content-selection-button flex flex-column"
              :class="{ active: selectedSection === 4 }"
              @click="viewChapter()"
            >
              <span class="fs-14 fw-600">Chapter</span>
              <span class="fw-400 fs-12 blk-text" :title="selectedChapterName">{{
                textEllipsis(selectedChapterName, 30) || ""
              }}</span>
            </div>
            <div
              class="content-selection-button flex flex-column"
              :class="{ active: selectedSection === 5 }"
              @click="viewCluster()"
            >
              <span class="fs-14 fw-600">Cluster</span>
              <span
                class="fw-400 fs-12 blk-text"
                v-if="selectedKscClusterName.length > 0"
                :title="selectedKscClusterName.toString()"
                >{{ textEllipsis(selectedKscClusterName.toString(), 30) || "" }}</span
              >
            </div>
            <div
              class="content-selection-button flex flex-column"
              :class="{ active: selectedSection === 6 }"
              @click="viewQuestionType()"
            >
              <span class="fs-14 fw-600">Question Type</span>
              <span
                class="fw-400 fs-12 blk-text"
                v-if="selectedQuestionTypeName"
                :title="selectedQuestionTypeName.toString()"
                >{{ selectedQuestionTypeName }}</span
              >
            </div>
            <div
              class="content-selection-button flex flex-column"
              :class="{ active: selectedSection === 7 }"
              @click="viewBatch()"
            >
              <span class="fs-14 fw-600">Batch</span>
              <span
                class="fw-400 fs-12 blk-text"
                v-if="selectedBatchName.length > 0"
                :title="selectedBatchName.toString()"
                >{{ textEllipsis(selectedBatchName.toString(), 30) || "" }}</span
              >
            </div>
            <div
              class="content-selection-button flex flex-column"
              :class="{ active: selectedSection === 8 }"
              @click="viewAssignmentType()"
            >
              <span class="fs-14 fw-600">Assignment Type</span>
              <span class="fw-400 fs-12 blk-text">{{ selectedAssignmentTypeName }}</span>
            </div>
          </div>
        </div>
        <div
          class="col s12 l9 margin-0 height-100 right-section-mobile"
          style="background-color: #f7f8fd"
        >
          <div>
            <div class="content-search" v-if="selectedSection !== 7">
              <input
                type="text"
                style="max-width: 93%"
                placeholder="Search..."
                v-model="searchValue"
              />
              <img
                class="search_img"
                :src="require(`../assets/Search.svg`)"
                alt="Search"
                style="position: absolute; top: 10px; right: 10px"
              />
            </div>
            <div class="pd-20" v-if="selectedSection === 1">
              <p class="instruction-line" v-if="filterCourseList.length > 0">
                Please select a course.
              </p>
              <p class="instruction-line" v-if="filterCourseList.length === 0">
                There are no courses available.
              </p>
              <div class="course-btn-container">
                <button
                  class="course-button"
                  :class="{ selected: Number(this.selectedCourse) === Number(course.CourseId) }"
                  :value="course.CourseId"
                  v-for="(course, index) in filterCourseList"
                  :key="index"
                  @click="onSelectCourse(course)"
                >
                  {{ course.CourseName }}
                </button>
              </div>
              <div class="mt-30">
                <button
                  class="next-button mt30 pointer"
                  :class="{ deactive: this.selectedCourse === null }"
                  @click="nextSection()"
                >
                  Next
                </button>
              </div>
            </div>
            <div class="pd-20" v-if="selectedSection === 2">
              <p class="instruction-line" v-if="filterClassList.length > 0">
                Please select a class. You can select multiple classes.
              </p>
              <p class="instruction-line" v-if="filterClassList.length === 0">
                There is no class available.
              </p>
              <div class="course-btn-container">
                <button
                  class="course-button"
                  :class="{ selected: this.selectedClass.includes(classItem) }"
                  :value="classItem.ClassId"
                  v-for="(classItem, index) in filterClassList"
                  :key="index"
                  @click="onSelectClass(classItem)"
                >
                  {{ classItem.DisplayName }}
                </button>
              </div>
              <div class="mt-30">
                <button
                  class="next-button mt30 pointer"
                  :class="{ deactive: this.selectedClass.length === 0 }"
                  @click="nextSection()"
                >
                  Next
                </button>
              </div>
            </div>
            <div class="pd-20" v-if="selectedSection === 3">
              <p class="instruction-line" v-if="filterCourseSubjectList.length > 0">
                Please select a subject. You can select multiple subjects.
              </p>
              <p class="instruction-line" v-if="filterCourseSubjectList.length === 0">
                There is no subject available.
              </p>
              <div class="course-btn-container">
                <button
                  class="course-button"
                  :class="{ selected: this.selectedSubject.includes(subject) }"
                  :value="subject.SubjectId"
                  v-for="(subject, index) in filterCourseSubjectList"
                  :key="index"
                  @click="onSelectSubject(subject)"
                >
                  {{ subject.SubjectName }}
                </button>
              </div>
              <div class="mt-30">
                <button
                  class="next-button mt30 pointer"
                  :class="{ deactive: this.selectedSubject.length === 0 }"
                  @click="nextSection()"
                >
                  Next
                </button>
              </div>
            </div>
            <div class="pd-20" v-if="selectedSection === 4">
              <p class="instruction-line" v-if="filterChapterList.length > 0">
                Please select at least 1 chapter from each subject. You can select multiple
                chapters.
              </p>
              <p class="instruction-line" v-if="filterChapterList.length === 0">
                There is no chapter available.
              </p>
              <div class="course-btn-container">
                <div class="scroll-section">
                  <div v-for="(data, index1) in filterChapterList" :key="index1" class="mt10">
                    <div class="flex flex-between items-center" style="padding-right: 20px">
                      <p class="subject-name" v-if="data.topicList.length > 0">
                        {{ data.subjectName }}
                      </p>
                      <div class="select-all" v-if="data.topicList.length > 0">
                        <input
                          type="checkbox"
                          :id="index1 + 'chapter'"
                          class="pointer"
                          :checked="isAllChapterSelected(index1, data.subjectId)"
                          @change="selectAllChapters($event, data, index1, data.subjectId)"
                          name="chapter"
                        />
                        <label :for="index1 + 'chapter'" class="pointer fw-400 fs-13 dark-text"
                          >Select All</label
                        >
                      </div>
                    </div>
                    <div class="flex mt10" style="flex-wrap: wrap">
                      <button
                        class="course-button"
                        :class="{ selected: selectedChapter.includes(topic) }"
                        v-for="(topic, index) in data.topicList"
                        :key="index"
                        @click="selectChapters(topic, data.subjectName, index1)"
                      >
                        {{ topic.ClassName + "-" + topic.ChapterName }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-30">
                <button
                  class="next-button mt30 pointer"
                  :class="{ deactive: this.selectedChapter.length === 0 || validateChapters() }"
                  @click="nextSection()"
                >
                  Next
                </button>
              </div>
            </div>
            <div class="pd-20" v-if="selectedSection === 5">
              <p class="instruction-line" v-if="filterKscClusterList.length > 0">
                Please select KSC Cluster. You can select multiple KSC Clusters.
              </p>
              <p class="instruction-line" v-if="filterKscClusterList.length === 0">
                There is no KSC Clusters available.
              </p>
              <div class="course-btn-container" v-if="filterKscClusterList.length > 0">
                <div class="scroll-section">
                  <div v-for="(data, index1) in filterKscClusterList" :key="index1" class="mt10">
                    <div class="flex flex-between items-center" style="padding-right: 20px">
                      <p class="subject-name" v-if="data.clusters.length > 0">
                        {{ data.ChapterName }}
                      </p>
                      <div class="select-all" v-if="data.clusters.length > 0">
                        <input
                          type="checkbox"
                          :id="index1 + 'chapter'"
                          class="pointer"
                          :checked="isAllClusterSelected(index1, data.CourseChapterId)"
                          @change="selectAllClusters($event, data, index1, data.CourseChapterId)"
                          name="chapter"
                        />
                        <label :for="index1 + 'chapter'" class="pointer fw-400 fs-13 dark-text"
                          >Select All</label
                        >
                      </div>
                    </div>
                    <div class="flex mt10" style="flex-wrap: wrap">
                      <button
                        class="course-button"
                        :class="{ selected: selectedKscClusterIds.includes(topic) }"
                        v-for="(topic, index) in data.clusters"
                        :key="index"
                        @click="selectClusters(topic, data.ChapterName, index1)"
                      >
                        {{ topic.KSCClusterName }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-30">
                <button
                  class="next-button mt30 pointer"
                  :class="{ deactive: this.selectedKscClusterIds.length === 0 }"
                  @click="nextSection()"
                >
                  Next
                </button>
              </div>
            </div>
            <div class="pd-20" v-if="selectedSection === 6">
              <div class="flex flex-column" style="margin-bottom: 20px">
                <div style="margin-bottom: 20px">
                  <span class="fs-14 fw-700 mb8"
                    >Assignment Title <span class="red-text">*</span></span
                  >
                </div>
                <input
                  type="text"
                  class="browser-default"
                  placeholder="Title"
                  id="test-input"
                  v-model="assignmentTitle"
                />
              </div>
              <div style="margin-bottom: 20px">
                <span class="fs-14 fw-700 mb8">Question Type <span class="red-text">*</span></span>
              </div>
              <div class="course-btn-container">
                <button
                  class="course-button"
                  :class="{ selected: this.selectedQuestionTypeId === question.QuestionTypeId }"
                  :value="question.QuestionTypeId"
                  v-for="(question, index) in questionList"
                  :key="index"
                  @click="onSelectQuestionType(question)"
                >
                  {{ question.QuestionType }}
                </button>
              </div>
              <div class="mt-30">
                <button
                  class="next-button mt30 pointer"
                  :class="{
                    deactive: this.selectedQuestionTypeId === null || this.assignmentTitle === null,
                  }"
                  @click="nextSection()"
                >
                  Next
                </button>
              </div>
            </div>
            <div class="pd-20" v-if="selectedSection === 7">
              <p class="instruction-line" v-if="filterBatchList.length > 0">
                Please select batch. You can select multiple batches.
              </p>
              <p class="instruction-line" v-if="filterBatchList.length === 0">
                There is no batch available for the selected course.
              </p>
              <div class="course-btn-container" v-if="filterBatchList.length > 0">
                <div class="scroll-section">
                  <div v-for="(data, index1) in filterBatchList" :key="index1" class="mt10">
                    <div class="flex flex-between items-center" style="padding-right: 20px">
                      <p class="subject-name" v-if="data.batches.length > 0">
                        {{ data.AffiliationName }}
                      </p>
                      <div class="select-all" v-if="data.batches.length > 0">
                        <input
                          type="checkbox"
                          :id="index1 + 'chapter'"
                          class="pointer"
                          :checked="checkIsAllBatchSelected(index1, data.AffiliationId)"
                          @change="selectAllBatch($event, data, index1, data.AffiliationId)"
                          name="chapter"
                        />
                        <label :for="index1 + 'chapter'" class="pointer fw-400 fs-13 dark-text"
                          >Select All</label
                        >
                      </div>
                    </div>
                    <div class="flex mt10" style="flex-wrap: wrap">
                      <button
                        class="course-button"
                        :class="{ selected: selectedBatchIds.includes(topic) }"
                        v-for="(topic, index) in data.batches"
                        :key="index"
                        @click="onSelectBatch(topic, data.Name, index1)"
                      >
                        {{ topic.Name }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-30">
                <button
                  class="next-button mt30 pointer"
                  :class="{ deactive: this.selectedBatchIds.length === 0 }"
                  @click="nextSection()"
                >
                  Next
                </button>
              </div>
            </div>
            <div class="pd-20" v-if="selectedSection === 8">
              <div class="course-btn-container">
                <button
                  class="course-button"
                  @click="onSelectAssignmentType(index)"
                  :class="{ selected: assignment.isSelected }"
                  v-for="(assignment, index) in assignmentTypeList"
                  :key="index"
                >
                  {{ assignment.type }}
                </button>
              </div>
              <div v-if="commonAssiValidation" style="color: red">
                Please select Questions for Common Assignment.
              </div>
              <div v-if="fileValidation" style="color: red">Please uplaod a Pdf File.</div>
              <div
                class="mt-30"
                v-if="selectedAssignmentType === 1 || selectedAssignmentType === 2"
              >
                <!-- <span style="opacity: 0;">Assignment Selection Button</span> -->
                <div v-if="this.isFileUploaded === true" style="color: green">
                  {{ this.uploadedFileName }} file uploaded
                </div>
              </div>
              <div class="mt-30">
                <button
                  class="create-assignment-btn mt30 pointer"
                  :class="{
                    deactive: !assignmentTypeList.some((item) => item.isSelected === true),
                  }"
                  @click="CreateAssignment()"
                >
                  Create Assignment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <transition name="modalSlide">
        <div class="assignment-modal-body" v-if="showCommonAssignmentModal">
          <!-- <div class="modal-header document-upload-header">
                      <span style="font-size: 20px;padding:5px;font-weight:600;">Select the Question For Common Assignment</span>
                      <span style="font-size: 20px;padding:5px;font-weight:600;">Question Selected: {{selectedCommonQuestions.length}}</span>
                  </div> -->
          <div class="modalHeader flex flex-between" style="border-bottom: 1px solid #e0e4f0">
            <div>
              <h6 style="font-size: 18px; font-weight: 700; padding-left: 28px">
                Select the Question For Common Assignment
              </h6>
            </div>
            <div class="flex flex-between">
              <div class="modal-btns col l6s12">
                <button class="modal-btn blue-bg" @click="markAndCloseAfterQuestionSelection()">
                  DONE
                </button>
              </div>
              <div class="col l3">
                <img
                  :src="require(`../assets/modalClose.svg`)"
                  style="
                    padding-top: 5px;
                    padding-right: 15px;
                    padding-bottom: 10px;
                    cursor: pointer;
                  "
                  @click="showDeleteModalWarning()"
                />
              </div>
            </div>
          </div>
          <div class="flex flex-between" style="padding-left: 28px; padding-right: 25px">
            <div>
              <h6 style="padding-bottom: 5px; font-size: 15px; font-weight: 600">
                Total Questions: {{ this.commonQuestionList.length }}
              </h6>
            </div>
            <div>
              <h6 style="padding-bottom: 5px; font-size: 15px; font-weight: 600">
                Question Selected: {{ selectedCommonQuestions.length }}
              </h6>
            </div>
            <!-- <div style="margin-top: 11px; margin-bottom: 7px;"><button class="loadMoreQuestionsButton" @click="loadMoreQuestions()">Load More Questions</button></div> -->
          </div>
          <div class="row">
            <div class="filterSection scroll-verticle-bar col s12 l3">
              <h6
                style="
                  padding-left: 15px;
                  padding-bottom: 15px;
                  font-size: 16px;
                  font-weight: 700;
                  border-bottom: 1px solid #e0e4f0;
                  margin-bottom: 0px;
                "
              >
                Filter
              </h6>
              <div>
                <div
                  class="flex"
                  style="
                    width: 100%;
                    justify-content: space-between;
                    padding-left: 15px;
                    padding-right: 10px;
                    padding-top: 20px;
                    margin-bottom: 10px;
                    border-bottom: 1px solid rgb(224, 228, 240);
                    padding-bottom: 15px;
                  "
                >
                  <span
                    class="flex question-header flex-right nowrap"
                    style="font-size: 13px; font-weight: 700"
                    >All Questions</span
                  >
                  <label class="switch">
                    <input
                      type="checkbox"
                      @click="this.toggleassignmentChkBox()"
                      :checked="this.assignmentQnChkBox"
                    />
                    <div class="slider round"></div>
                  </label>
                  <span class="question-header nowrap" style="font-size: 13px; font-weight: 700">
                    Assignment Qs</span
                  >
                </div>
              </div>
              <div class="commonFilterComponent">
                <CommonFilterComponent
                  :entityDataObject="this.subjectsFilter"
                  :isSingleSelect="true"
                  :key="selectedSubjectIdModal"
                  :selectedId="selectedSubjectIdModal"
                  :showAll="false"
                  @selectedEntityId="getSelectedQuestionsBySubject($event)"
                />
              </div>
              <div class="commonFilterComponent">
                <CommonFilterComponent
                  :entityDataObject="this.chaptersFilter"
                  :isSingleSelect="true"
                  :key="selectedChapterIdModal"
                  :selectedId="selectedChapterIdModal"
                  :showAll="false"
                  @selectedEntityId="getSelectedQuestionsByChapter($event)"
                />
              </div>
              <div class="commonFilterComponent">
                <CommonFilterComponent
                  :entityDataObject="this.conceptObject"
                  :isCluster="true"
                  :isChapter="false"
                  :ChapterList="chapterList"
                  @selectedEntityId="getSelectedEntityId($event)"
                />
              </div>
              <DifficultLevelComponent
                :selectedClusterIdQuestionslist="selectedClusterIdQuestions"
                @selectedDiffLevel="getCommonQDiffLevel($event)"
              />
              <!-- <div class="flex items-center" style="margin-left: 15px;">
                              <label class="pointer dark-text fs-13 fw-700" for="commonassignmentQuestions" style="padding-right: 38px;">Assignment Questions</label>
                              <input type="checkbox" v-model="assignmentQuestions" :checked="assignmentQuestions" id="commonassignmentQuestions" @change="commonAssignmentQuestions()" class="pointer" style="top: 3px; width: 17px; height: 17px;">
                          </div> -->
            </div>
            <div class="w-100 col s12 l9">
              <div
                style="text-align: center; max-width: 850px; width: 100%"
                class="flex items-center"
                v-if="commonQuestionList.length === 0"
              >
                <span
                  class="material-icons material-symbols-outlined red-text"
                  style="font-size: 60px"
                  >error</span
                >
                <p
                  class="fs-18 red-text fw-600"
                  style="margin-left: 10px !important; text-align: left"
                  v-if="errorMsg === ''"
                >
                  No assignment questions available please switch to all questions
                </p>
                <p
                  class="fs-18 red-text fw-600"
                  style="margin-left: 10px !important; text-align: left"
                  v-if="errorMsg !== ''"
                >
                  {{ errorMsg }}
                </p>
              </div>
              <div class="scroll-verticle-bar" v-if="commonQuestionList.length > 0">
                <ManualQuestion
                  v-for="item in commonQuestionList"
                  :key="item.QuestionId"
                  :selectedDiffGrp="
                    item.DifficultyGroup ? item.DifficultyGroup.toLowerCase() : 'easy'
                  "
                  :Difficulty="item.DifficultyGroup ? item.DifficultyGroup.toLowerCase() : 'easy'"
                  :QuestionDetail="item"
                  @click="selectQns(item)"
                  :isQustionSelected="
                    selectedCommonQuestions.includes(item.QuestionId) ? true : false
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <Modal
      :show="openUploadAssignmentModel"
      @close="openUploadAssignmentModel = false"
      :showHeader="false"
      :showCloseBtn="false"
    >
      <template v-slot:body class="auto-modal-body">
        <div class="modal-header document-upload-header">
          <span class="fw-600 fs-20 dark-font">Choose the Assignment File</span>
        </div>
        <br />
        <div>
          <input id="file1" type="file" accept="application/pdf" @change="onUploadFile($event)" />
        </div>
        <div v-if="fileValidation" style="color: red">
          <span>Please Upload a PDF File</span>
        </div>
        <br />
        <br />
        <div class="modal-btns">
          <button class="modal-btn blue-bg" @click="createStandardAssignment()">Upload</button>
          <button class="modal-btn silver-bg" @click="cancelPublish()">Cancel</button>
        </div>
      </template>
    </Modal>
    <Modal
      :show="openAutoAssignmentModel"
      @close="openAutoAssignmentModel = false"
      :showHeader="false"
      :showCloseBtn="true"
    >
      <template v-slot:body class="auto-modal-body">
        <!-- <div class="row">
                        <span style="color:lightgreen; font-weight:600;">Assignment Saved Successfully</span>
                    </div> -->
        <div class="row">
          <span class="dark-font"
            >Click Create Assignment to Create and Publish Auto Assignment</span
          >
        </div>
        <hr />
        <div style="display: flex; justify-content: center; margin-top: 20px">
          <button class="modal-btn blue-bg" @click="openAutoAssignmentModel = false">Okay</button>
        </div>
      </template>
    </Modal>
    <Modal :show="showDeleteModal" :showHeader="false" @close="showDeleteModal = false">
      <template v-slot:body>
        <div class="warning-section-modal">
          <div class="flex flex-center" style="margin-bottom: 20px; padding-top: 30px">
            <img class="" :src="require(`../assets/errorsvg.svg`)" style="width: 120px" />
          </div>
          <div class="flex flex-center" style="flex-direction: column">
            <span class="mesage-body fs-14 fw-500 dark-text" style="white-space: pre-line">
              All the selected questions will be cleared ,are you sure want to close the modal?
            </span>
          </div>
          <div class="flex content-even" style="padding: 20px">
            <button class="confirm-btn fs-13 fw-600 ln-18" @click="closeAssignmentPopop()">
              YES
            </button>
            <button class="cancel-btn fs-13 fw-600 ln-18" @click="showDeleteModal = false">
              NO
            </button>
          </div>
        </div>
      </template>
    </Modal>
    <Modal :show="showNoQuestionsModal" :showHeader="false" @close="showNoQuestionsModal = false">
      <template v-slot:body>
        <div class="warning-section-modal">
          <div class="flex flex-center" style="margin-bottom: 20px; padding-top: 30px">
            <img class="" :src="require(`../assets/errorsvg.svg`)" style="width: 120px" />
          </div>
          <div class="flex flex-center" style="flex-direction: column">
            <span class="mesage-body fs-14 fw-500 dark-text" style="white-space: pre-line">
              No questions have been selected , please go back and select to create an assignment.
            </span>
          </div>
          <div class="flex content-even" style="padding: 20px">
            <button class="cancel-btn fs-13 fw-600 ln-18" @click="showNoQuestionsModal = false">
              GO BACK
            </button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import Modal from "../components/Modal.vue";
import API from "../Api";
import MobileAPI from "../Mobileapi";
import ManualQuestion from "../components/ManualQuestion.vue";
// import FilterComponent1 from "../components/FilterComponent1.vue";
import DifficultLevelComponent from "../components/DifficultLevelComponent";
import CommonFilterComponent from "../components/CommonFilterComponent.vue";

const toast = useToast();

export default {
  components: {
    Modal,
    ManualQuestion,
    DifficultLevelComponent,
    CommonFilterComponent,
  },
  data() {
    return {
      selectedSection: null,
      searchValue: "",
      isAllBatchSelected: false,
      isAllKscClusterSelected: false,
      commonAssiValidation: false,
      isMobileNavigationVisible: false,
      // for course
      selectedCourse: null,
      selectedCourseName: "",
      selectedClass: null,
      selectedClassName: "",
      selectedSubject: [],
      selectedSubjectName: "",
      selectedBatch: null,
      selectedBatchName: [],
      selectedChapter: [],
      selectedChapterName: "",
      selectedKscCluster: null,
      selectedKscClusterName: [],
      selectedQuestionTypeId: null,
      selectedQuestionTypeName: "",
      selectedAssignmentType: null,
      selectedAssignmentTypeName: "",

      courseList: [],
      batchList: [],
      classList: [],
      chapterList: [],
      kscClusterList: [],
      courseSubjectList: [],
      courseBatchList: [],
      selectedBatchIds: [],
      selectedKscClusterIds: [],
      questionList: [],

      // assignment type
      assignmentTypeList: [
        {
          id: 1,
          type: "Common Assignment",
          isSelected: false,
        },
        {
          id: 2,
          type: "PDF Assignment",
          isSelected: false,
        },
        // {
        //   id: 3,
        //   type: "Auto Assignment",
        //   isSelected: false,
        // },
      ],
      openUploadAssignmentModel: false,
      openAutoAssignmentModel: false,

      // pdf assignment
      file: "",
      fileType: "",
      uploadedFileName: "",
      isFileUploaded: false,
      fileField1: "",
      fileValidation: false,
      subjectValidation: false,
      topidId: null,
      pdfassignmentId: 0,

      // common assignment
      showCommonAssignmentModal: false,

      commonQuestionClusterList: [],
      allManualQnsList: [],
      allAssignmentQnsList: [],
      selectedKscClusterList: [],

      commonQuestionList: [],
      commonAssignmentKscQList: [],
      diffLevelFilterByClusterId: [],

      assignmentQnChkBox: true,
      uniqueClusterArray: [],

      isAssignmentQuestions: false,
      selctedClusterList: [],
      selectedCommonQuestionCluster: [],
      errorMsg: "",

      commonQsDifficultyList: [],
      democommonQsDifficultyList: [],
      selectedCommonQuestions: [],

      // create assignment
      addedAssignment: "",

      // on navigation
      teacherDashAssigData: [],
      assignmentTitle: null,
      chapterCountList: [],
      selectedChapters: [],
      totalChapterCount: null,
      clusterListNew: [],
      tempClusterList: [],
      subjectsFilter: [],
      chaptersFilter: [],
      selectedChapterIdModal: null,
      selectedSubjectIdModal: null,
      showDeleteModal: false,
      showNoQuestionsModal: false,
      batchListNew: [],
    };
  },
  beforeMount() {
    document.body.style.backgroundColor = "#F6F8FB";
  },

  mounted() {
    this.selectedSection = 1;
    this.selectedCourse = null;
    this.courseList = [];
    this.classList = [];
    this.batchList = [];
    this.courseSubjectList = [];
    // this.AffiliationBatch = [];
    this.selectedBatchIds = [];
    this.selectedBatchName = [];
    this.getTestMetaDataNew(1);
  },

  computed: {
    filterCourseList() {
      return this.courseList.filter((course) =>
        course.CourseName.toLowerCase().includes(this.searchValue.toLowerCase()),
      );
    },
    filterBatchList() {
      return this.batchListNew.map((item) => ({
        ...item,
        batches: item.batchList.filter((chapter) =>
          chapter.Name.toLowerCase().includes(this.searchValue.toLowerCase()),
        ),
      }));
    },
    filterClassList() {
      return this.classList.filter((classItem) =>
        classItem.DisplayName.toLowerCase().includes(this.searchValue.toLowerCase()),
      );
    },
    filterCourseSubjectList() {
      return this.courseSubjectList.filter((subject) =>
        subject.SubjectName.toLowerCase().includes(this.searchValue.toLowerCase()),
      );
    },
    filterChapterList() {
      // return this.chapterList;
      // return this.chapterList.filter((chapter) => chapter.ChapterName.toLowerCase().includes(this.searchValue.toLowerCase()));
      return this.chapterList.map((item) => ({
        ...item,
        topicList: item.topicList.filter((chapter) =>
          chapter.ChapterName.toLowerCase().includes(this.searchValue.toLowerCase()),
        ),
      }));
    },
    filterKscClusterList() {
      return this.tempClusterList.map((item) => ({
        ...item,
        clusters: item.clusters.filter((chapter) =>
          chapter.KSCClusterName.toLowerCase().includes(this.searchValue.toLowerCase()),
        ),
      }));
    },
    selectedClusterIdQuestions() {
      return this.commonAssignmentKscQList.filter((item1) =>
        this.selectedKscClusterIds.some((item2) => item2.KSCClusterId === item1.KSCClusterId),
      );
    },
  },

  methods: {
    groupBatchesByAffiliation(chapterlist) {
      const batchListTemp = {};

      // eslint-disable-next-line no-restricted-syntax
      for (const chapter of chapterlist) {
        const affiliationId = chapter.AffiliationId;

        if (!batchListTemp[affiliationId]) {
          batchListTemp[affiliationId] = {
            AffiliationId: affiliationId,
            AffiliationName: chapter.AffiliationName,
            batchList: [],
          };
        }

        batchListTemp[affiliationId].batchList.push({
          Id: chapter.Id,
          Code: chapter.Code,
          Name: chapter.Name,
          AffiliationId: affiliationId,
        });
      }
      this.batchListNew = Object.values(batchListTemp);
    },
    showDeleteModalWarning() {
      if (this.selectedCommonQuestions != null && this.selectedCommonQuestions.length > 0) {
        this.showDeleteModal = true;
      } else {
        this.closeAssignmentPopop();
      }
    },
    validateChapters() {
      // const topicList = this.topicList.filter((item) => item.topicList.length > 0);
      if (this.selectedChapter.length > 0) {
        const uniqueSubject = [...new Set(this.selectedChapter.map((item) => item.SubjectId))];
        if (uniqueSubject.length === this.selectedSubject.length) {
          return false;
        }
        return true;
      }
      return true;
    },
    selectChapters(data, subjectName, index) {
      this.chapterCountList = [];
      if (this.selectedChapter.includes(data)) {
        const index1 = this.selectedChapter.indexOf(data);
        this.selectedChapter.splice(index1, 1);
      } else {
        this.selectedChapter.push(data);
      }

      if (this.selectedChapter.length > 0) {
        const courseChapterArray = this.selectedChapter.map((a) => a.CourseChapterId);
        this.tempClusterList = this.clusterListNew.filter((chapter) =>
          courseChapterArray.includes(chapter.CourseChapterId),
        );
      } else {
        this.tempClusterList = [];
      }
      this.isAllChapterSelected(index, data.SubjectId);
      if (this.selectedChapter !== null) {
        const chapterData = {
          ChapterIds: this.selectedChapter.map((a) => a.ChapterId).join(","),
          TopicId: 0,
          SubjectId: 0,
          CourseId: 0,
          ClassId: 0,
          UserId: 0,
        };
        MobileAPI.getQuestionTypeAvailableForTest(chapterData, (response) => {
          // eslint-disable-next-line eqeqeq
          if (response.message === "Success") {
            this.$store.dispatch("showLoader", false);
            if (response.data !== null && response.data.length > 0) {
              this.questionList = response.data;
              if (this.questionList != null && this.questionList.length > 0) {
                this.selectQuestionType(this.questionList[0]);
              } else {
                this.questionList = [];
                toast.error("No QuestionType Available");
              }
            } else {
              this.questionList = [];
              toast.error("No QuestionType Available");
            }
          } else {
            this.questionList = [];
            this.$store.dispatch("showLoader", false);
          }
        });
      }
    },
    selectAllChapters(event, chapterData, index, subjectId) {
      if (event.target.checked) {
        const topicList = chapterData.topicList.flat();
        topicList.forEach((item) => {
          if (!this.selectedChapter.includes(item)) {
            this.selectedChapter.push(item);
          }
        });
      } else {
        // eslint-disable-next-line no-plusplus, no-lonely-if
        if (this.selectedChapter.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let i = this.selectedChapter.length - 1; i >= 0; i--) {
            if (this.selectedChapter[i].SubjectId === subjectId) {
              this.selectedChapter.splice(i, 1);
            }
          }
        }
      }
      if (this.selectedChapter.length > 0) {
        const courseChapterArray = this.selectedChapter.map((a) => a.CourseChapterId);
        this.tempClusterList = this.clusterListNew.filter((chapter) =>
          courseChapterArray.includes(chapter.CourseChapterId),
        );
      } else {
        this.tempClusterList = [];
      }
      this.isAllChapterSelected(index, chapterData.SubjectId);
      if (this.selectedChapter !== null) {
        const chapterObj = {
          ChapterIds: this.selectedChapter.map((a) => a.ChapterId).join(","),
          TopicId: 0,
          SubjectId: 0,
          CourseId: 0,
          ClassId: 0,
          UserId: 0,
        };
        MobileAPI.getQuestionTypeAvailableForTest(chapterObj, (response) => {
          // eslint-disable-next-line eqeqeq
          if (response.message === "Success") {
            this.$store.dispatch("showLoader", false);
            if (response.data !== null && response.data.length > 0) {
              this.questionList = response.data;
              this.selectQuestionType(this.questionList[0]);
            } else {
              this.questionList = [];
              toast.error("No QuestionType Available");
            }
          } else {
            this.questionList = [];
            this.$store.dispatch("showLoader", false);
          }
        });
      }
    },
    isAllChapterSelected(index, subjectId) {
      let returnData = false;
      const subjectChapters = this.chapterList.find((a) => a.subjectId === subjectId);
      const selectedChapters = this.selectedChapter.filter((a) => a.SubjectId === subjectId);
      if (
        subjectChapters != null &&
        subjectChapters.topicList != null &&
        subjectChapters.topicList.length > 0 &&
        selectedChapters.length > 0
      ) {
        if (subjectChapters.topicList.length === selectedChapters.length) {
          returnData = true;
        }
      }
      return returnData;
    },
    isAllClusterSelected(index, clusterId) {
      let returnData = false;
      const subjectChapters = this.tempClusterList.find((a) => a.CourseChapterId === clusterId);
      const selectedChapters = this.selectedKscClusterIds.filter(
        (a) => a.CourseChapterId === clusterId,
      );
      if (
        subjectChapters != null &&
        subjectChapters.clusters != null &&
        subjectChapters.clusters.length > 0 &&
        selectedChapters.length > 0
      ) {
        if (subjectChapters.clusters.length === selectedChapters.length) {
          returnData = true;
        }
      }
      return returnData;
    },
    checkIsAllBatchSelected(index, clusterId) {
      let returnData = false;
      const batchListTemp = this.selectedBatchIds.filter((a) => a.AffiliationId === clusterId);
      const batchObj = this.batchListNew.filter((a) => a.AffiliationId === clusterId)[0];
      // ///this.tempClusterList = this.clusterListNew.filter((chapter) => batchListTemp.includes(chapter.Id));
      // const subjectChapters = this.tempClusterList.find((a) => a.CourseChapterId === clusterId);
      // const selectedChapters = this.selectedKscClusterIds.filter((a) => a.CourseChapterId === clusterId);
      if (
        batchListTemp != null &&
        batchObj.batchList != null &&
        batchObj.batchList.length > 0 &&
        batchListTemp.length > 0
      ) {
        if (batchObj.batchList.length === batchListTemp.length) {
          returnData = true;
        }
      }
      return returnData;
    },
    selectClusters(data, subjectName, index) {
      this.chapterCountList = [];
      if (this.selectedKscClusterIds.includes(data)) {
        const index1 = this.selectedKscClusterIds.indexOf(data);
        this.selectedKscClusterIds.splice(index1, 1);
      } else {
        this.selectedKscClusterIds.push(data);
      }
      this.isAllClusterSelected(index, data.CourseChapterId);
    },
    selectAllClusters(event, chapterData, index, subjectId) {
      if (event.target.checked) {
        const topicList = chapterData.clusters.flat();
        topicList.forEach((item) => {
          if (!this.selectedKscClusterIds.includes(item)) {
            this.selectedKscClusterIds.push(item);
          }
        });
      } else {
        // eslint-disable-next-line no-plusplus, no-lonely-if
        if (this.selectedKscClusterIds.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let i = this.selectedKscClusterIds.length - 1; i >= 0; i--) {
            if (this.selectedKscClusterIds[i].CourseChapterId === subjectId) {
              this.selectedKscClusterIds.splice(i, 1);
            }
          }
        }
      }
      this.isAllClusterSelected(index, chapterData.CourseChapterId);
    },
    getTestMetaDataNew(onlyCourses, courseId = 0) {
      API.getProfile((profile) => {
        this.userProfileData = profile;
        if (this.userProfileData !== null || this.userProfileData !== undefined) {
          this.selectedSection = 1;
          const user = this.$store.getters.user;
          this.userDetails = user;
          this.affiliationId = this.userDetails.AffiliationId;
          const data = {
            onlyCourses,
            courseId,
            forLPS: 1,
          };
          MobileAPI.getCourseMetaData(this.affiliationId, data, (response) => {
            if (onlyCourses === 1) {
              this.courseList = response.data[0];
              this.classList = response.data[1];
            } else {
              this.courseSubjectList = response.data[0];
              this.batchList = response.data[1];
              this.courseBatchList = this.batchList.filter((a) => a.CourseId === courseId);
              if (this.courseBatchList.length === 0) {
                toast.error("No batch available for selected course.", {
                  timeout: 2500,
                });
              } else {
                this.groupBatchesByAffiliation(this.courseBatchList);
              }

              this.classList = this.classList.filter((a) => a.CourseId === courseId);
            }
          });
        }
      });
    },
    textEllipsis(input, num) {
      if (input && input.length > num && input) {
        return `${input.substring(0, num)}...`;
      }
      return input;
    },

    onSelectCourse(selectedCourse) {
      // reset values
      this.selectedCourse = null;
      this.selectedCourseName = "";
      this.selectedClass = [];
      this.selectedClassName = "";
      this.selectedSubject = [];
      this.selectedSubjectName = "";
      this.selectedBatch = null;
      this.selectedBatchName = [];
      this.selectedChapter = [];
      this.selectedChapterName = "";
      this.selectedKscCluster = null;
      this.selectedKscClusterName = [];
      this.courseBatchList = [];
      // this.classList = [];
      this.chapterList = [];
      this.kscClusterList = [];
      // this.courseSubjectList = [];
      this.selectedBatchIds = [];
      this.selectedKscClusterIds = [];
      this.isAllBatchSelected = false;

      this.selectedCourse = Number(selectedCourse.CourseId);
      this.selectedCourseName = selectedCourse.CourseName;
      this.getTestMetaDataNew(0, this.selectedCourse);
      MobileAPI.getClustersForCourse(
        this.selectedCourse,
        (response) => {
          this.clusterListNew = response.data;
        },
        () => {
          toast.error("Oops! Something went wrong. Please try again!", {
            timeout: 2500,
          });
        },
      );
    },
    selectAllBatch(event, chapterData, index, subjectId) {
      if (event.target.checked) {
        const topicList = chapterData.batchList.flat();
        topicList.forEach((item) => {
          if (!this.selectedBatchIds.includes(item)) {
            this.selectedBatchIds.push(item);
          }
        });
      } else {
        // eslint-disable-next-line no-plusplus, no-lonely-if
        if (this.selectedBatchIds.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let i = this.selectedBatchIds.length - 1; i >= 0; i--) {
            if (this.selectedBatchIds[i].AffiliationId === subjectId) {
              this.selectedBatchIds.splice(i, 1);
            }
          }
        }
      }
      this.checkIsAllBatchSelected(index, chapterData.AffiliationId);
    },
    onSelectBatch(selectedBatch, index) {
      this.chapterCountList = [];
      if (this.selectedBatchIds.includes(selectedBatch)) {
        const index1 = this.selectedBatchIds.indexOf(selectedBatch);
        this.selectedBatchIds.splice(index1, 1);
      } else {
        this.selectedBatchIds.push(selectedBatch);
      }
      this.checkIsAllBatchSelected(index, selectedBatch.AffiliationId);
    },
    checkAllBatch() {
      if (this.courseBatchList.length === this.selectedBatchIds.length) {
        this.isAllBatchSelected = true;
      } else {
        this.isAllBatchSelected = false;
      }
    },
    onSelectClass(selectedClass) {
      this.selectedClassName = "";
      if (this.selectedClass.includes(selectedClass)) {
        const index = this.selectedClass.indexOf(selectedClass);
        this.selectedClass.splice(index, 1);
      } else {
        this.selectedClass.push(selectedClass);
      }
      if (this.selectedClass.length > 0) {
        const classNameArray = this.selectedClass.map((a) => a.ClassName);
        this.selectedClassName = classNameArray.join(",");
      }
      // this.selectedClass = Number(selectedClass.ClassId);
      // this.selectedClassName = selectedClass.ClassName;
    },
    onSelectSubject(data) {
      if (this.selectedSubject.includes(data)) {
        // const index = this.selectCourseDetails.subjectsId.indexOf(data.SubjectId);
        const index1 = this.selectedSubject.indexOf(data);
        const index3 = this.chapterList.findIndex((obj) => obj.subjectName === data.SubjectName);
        this.selectedSubject.splice(index1, 1);
        this.chapterList.splice(index3, 1);
      } else {
        this.selectedSubject.push(data);
        if (this.selectedClass.length === 1) {
          this.chapterList.push({
            subjectName: data.SubjectName,
            subjectId: data.SubjectId,
            topicList: data.topicsList.filter(
              (obj) => obj.ClassId === this.selectedClass[0].ClassId,
            ),
          });
        } else {
          this.chapterList.push({
            subjectName: data.SubjectName,
            subjectId: data.SubjectId,
            topicList: data.topicsList,
          });
        }
      }
      if (this.selectedSubject.length > 0) {
        const classNameArray = this.selectedSubject.map((a) => a.SubjectName);
        this.selectedSubjectName = classNameArray.join(",");
      }
      // reset values
      // this.selectedChapter = null;
      // this.selectedChapterName = "";
      // this.chapterList = null;
      // this.selectedSubject = null;
      // this.selectedSubjectName = "";

      // this.selectedSubject = Number(selectedSubject.SubjectId);
      // this.selectedSubjectName = selectedSubject.SubjectName;
      // this.chapterList = selectedSubject.topicsList;
      // MobileAPI.getcourseClassSubjectChapter(this.selectedCourse, this.selectedClass, (response) => {
      //   const subjectChapterList = response.data;
      //   this.chapterList = subjectChapterList.filter((a) => a.SubjectId === this.selectedSubject);
      // });
    },
    onSelectAssignmentType(index) {
      this.commonAssiValidation = false;
      this.fileValidation = false;
      this.assignmentTypeList.forEach((e) => {
        e.isSelected = false;
      });
      this.assignmentTypeList[index].isSelected = true;
      this.selectedAssignmentType = Number(this.assignmentTypeList[index].id);
      this.selectedAssignmentTypeName = this.assignmentTypeList[index].type;
      if (this.selectedAssignmentType === 1) {
        this.OpenCommonAssignment();
      }
      if (this.selectedAssignmentType === 2) {
        this.OpenUploadAssignment();
      }
      if (this.selectedAssignmentType === 3) {
        this.OpenAutoAssignment();
      }
    },
    onSelectQuestionType(question) {
      this.selectedQuestionTypeId = Number(question.QuestionTypeId);
      this.selectedQuestionTypeName = question.QuestionType;
    },

    // common assignment modal

    OpenCommonAssignment() {
      this.isFileUploaded = false;
      this.commonQuestionClusterList = [];
      this.allManualQnsList = [];
      this.allAssignmentQnsList = [];
      this.selectedChapterIdModal =
        this.selectedChapter.length > 0 ? this.selectedChapter[0].CourseChapterId : 0;
      this.selectedSubjectIdModal =
        this.selectedSubject.length > 0 ? this.selectedSubject[0].SubjectId : 0;
      const data1 = {
        courseId: this.selectedCourse,
        batches: this.selectedBatchIds.map((a) => a.Id).join(","),
        courseChapterId: this.selectedChapterIdModal,
        questionType: this.selectedQuestionTypeId ? this.selectedQuestionTypeId : 1,
        questionCategoryId: 3,
      };
      const data2 = {
        courseId: this.selectedCourse,
        batches: this.selectedBatchIds.map((a) => a.Id).join(","),
        courseChapterId: this.selectedChapterIdModal,
        questionType: this.selectedQuestionTypeId ? this.selectedQuestionTypeId : 1,
        questionCategoryId: 1,
      };
      let QuestionList = [];
      this.selectedClusters =
        this.selectedKscClusterIds.length > 0 ? this.selectedKscClusterIds : [];
      this.$store.dispatch("showLoader", true);
      MobileAPI.getCreatTestManualQuestion(data1, (response) => {
        if (response.data.length > 0 && response.responseCode === 200) {
          // this.allAssignmentQnsList = response.data;

          this.allAssignmentQnsList = response.data.filter((item1) =>
            this.selectedClusters.some(
              (item) =>
                item.KSCClusterId === item1.KSCClusterId &&
                item.CourseChapterId === item1.CourseChapterId,
            ),
          );
          this.$store.dispatch("showLoader", false);
        }
        this.$store.dispatch("showLoader", true);
        MobileAPI.getCreatTestManualQuestion(data2, (resp) => {
          if (resp.data.length > 0 && resp.responseCode === 200) {
            // this.allManualQnsList = resp.data;
            this.allManualQnsList = resp.data.filter((item1) =>
              this.selectedClusters.some(
                (item) =>
                  item.KSCClusterId === item1.KSCClusterId &&
                  item.CourseChapterId === item1.CourseChapterId,
              ),
            );
            this.$store.dispatch("showLoader", false);
          }

          if (this.allAssignmentQnsList.length > 0) {
            QuestionList = this.allAssignmentQnsList;
            this.commonQuestionList = this.allAssignmentQnsList;
            this.commonAssignmentKscQList = this.commonQuestionList;
            this.diffLevelFilterByClusterId = this.commonQuestionList;
            this.assignmentQnChkBox = true;
            this.showCommonAssignmentModal = true;
          } else if (this.allManualQnsList.length > 0) {
            QuestionList = this.allManualQnsList;
            this.commonQuestionList = this.allManualQnsList;
            this.commonAssignmentKscQList = this.commonQuestionList;
            this.diffLevelFilterByClusterId = this.commonQuestionList;
            this.assignmentQnChkBox = false;
            this.showCommonAssignmentModal = true;
          }
          if (QuestionList.length > 0) {
            this.selectedClusters =
              this.selectedKscClusterIds.length > 0 ? this.selectedKscClusterIds : [];
            this.commonQuestionClusterList = QuestionList.filter((item1) =>
              this.selectedClusters.some((item) => item.KSCClusterId === item1.KSCClusterId),
            );
            this.showCommonAssignmentModal = true;
            this.$store.dispatch("showLoader", false);
          } else {
            this.showCommonAssignmentModal = false;
            toast.error("Question Not found", {
              timeout: 2500,
            });
            this.$store.dispatch("showLoader", false);
          }
        });
      });
      const tempClusterArray = this.selectedKscClusterIds.filter(
        (a) => a.CourseChapterId === this.selectedChapterIdModal,
      );
      this.uniqueClusterArray = tempClusterArray.map((item) => ({
        entityId: item.KSCClusterId,
        entityName: item.KSCClusterName,
      }));
      this.conceptObject = {
        Name: "Concept", // Component Title
        entityArray: this.uniqueClusterArray, // array
      };

      const subjectsObj = this.selectedSubject.map((item) => ({
        entityId: item.SubjectId,
        entityName: item.SubjectName,
      }));
      this.subjectsFilter = {
        Name: "Subjects", // Component Title
        entityArray: subjectsObj, // array
      };

      const tempChaptersArray = this.selectedChapter.filter(
        (a) => a.SubjectId === this.selectedSubjectIdModal,
      );

      const chaptersObj = tempChaptersArray.map((item) => ({
        entityId: item.CourseChapterId,
        entityName: item.ChapterName,
      }));
      this.chaptersFilter = {
        Name: "Chapters", // Component Title
        entityArray: chaptersObj, // array
      };
    },
    getAssignmentQuestions() {
      this.allAssignmentQnsList = [];
      this.allManualQnsList = [];
      if (this.assignmentQnChkBox) {
        this.getAssignmentTypeManualQuestions();
      } else {
        this.getAllManualQuestions();
      }
      const tempClusterArray = this.selectedKscClusterIds.filter(
        (a) => a.CourseChapterId === this.selectedChapterIdModal,
      );
      this.uniqueClusterArray = tempClusterArray.map((item) => ({
        entityId: item.KSCClusterId,
        entityName: item.KSCClusterName,
      }));
      this.conceptObject = {
        Name: "Concept", // Component Title
        entityArray: this.uniqueClusterArray, // array
      };
      const tempChaptersArray = this.selectedChapter.filter(
        (a) => a.SubjectId === this.selectedSubjectIdModal,
      );

      const chaptersObj = tempChaptersArray.map((item) => ({
        entityId: item.CourseChapterId,
        entityName: item.ChapterName,
      }));
      this.chaptersFilter = {
        Name: "Chapters", // Component Title
        entityArray: chaptersObj, // array
      };
      if (this.commonQuestionList.length === 0) {
        this.errorMsg = "No questions available for the selected filters.";
      } else {
        this.errorMsg = "";
      }
    },
    getAssignmentTypeManualQuestions() {
      let QuestionList = [];
      const data1 = {
        courseId: this.selectedCourse,
        batches: this.selectedBatchIds.map((a) => a.Id).join(","),
        courseChapterId: this.selectedChapterIdModal,
        questionType: this.selectedQuestionTypeId ? this.selectedQuestionTypeId : 1,
        questionCategoryId: 3,
      };
      MobileAPI.getCreatTestManualQuestion(data1, (response) => {
        if (response.data.length > 0 && response.responseCode === 200) {
          this.allAssignmentQnsList = response.data.filter((item1) =>
            this.selectedClusters.some((item) => item.KSCClusterId === item1.KSCClusterId),
          );
          this.$store.dispatch("showLoader", false);
        }
        if (this.allAssignmentQnsList.length > 0) {
          QuestionList = this.allAssignmentQnsList;
          this.commonQuestionList = this.allAssignmentQnsList;
        }
        if (QuestionList.length > 0) {
          this.selectedClusters =
            this.selectedKscClusterIds.length > 0 ? this.selectedKscClusterIds : [];
          // this.commonQuestionClusterList = QuestionList.filter((item1) => this.selectedClusters.some((item) => item.KSCClusterId === item1.KSCClusterId));
          this.$store.dispatch("showLoader", false);
        } else {
          this.commonQuestionList = [];
          toast.error("Question Not found", {
            timeout: 2500,
          });
        }
      });
    },
    getAllManualQuestions() {
      let QuestionList = [];
      const data2 = {
        courseId: this.selectedCourse,
        batches: this.selectedBatchIds.map((a) => a.Id).join(","),
        courseChapterId: this.selectedChapterIdModal,
        questionType: this.selectedQuestionTypeId ? this.selectedQuestionTypeId : 1,
        questionCategoryId: 1,
      };
      MobileAPI.getCreatTestManualQuestion(data2, (response) => {
        if (response.data.length > 0 && response.responseCode === 200) {
          this.allManualQnsList = response.data.filter((item1) =>
            this.selectedClusters.some((item) => item.KSCClusterId === item1.KSCClusterId),
          );
        }
        if (this.allManualQnsList.length > 0) {
          QuestionList = this.allManualQnsList;
          this.commonQuestionList = this.allManualQnsList;
        }
        if (QuestionList.length > 0) {
          this.selectedClusters =
            this.selectedKscClusterIds.length > 0 ? this.selectedKscClusterIds : [];
          // this.commonQuestionClusterList = QuestionList.filter((item1) => this.selectedClusters.some((item) => item.KSCClusterId === item1.KSCClusterId));
        } else {
          this.commonQuestionList = [];
          toast.error("Question Not found", {
            timeout: 2500,
          });
        }
      });
    },
    closeAssignmentPopop() {
      this.showCommonAssignmentModal = false;
      this.commonQuestionList = [];
      this.allManualQnsList = [];
      this.allAssignmentQnsList = [];
      this.selectedCommonQuestions = [];
      this.showDeleteModal = false;
      // toast.error("Question Not found", {
      //   timeout: 2500,
      // });
    },
    markAndCloseAfterQuestionSelection() {
      if (this.selectedCommonQuestions === null || this.selectedCommonQuestions.length === 0) {
        this.showNoQuestionsModal = true;
      } else {
        this.showCommonAssignmentModal = false;
        this.commonQuestionList = [];
        this.allManualQnsList = [];
        this.allAssignmentQnsList = [];
      }
    },
    toggleassignmentChkBox() {
      this.assignmentQnChkBox = !this.assignmentQnChkBox;
      this.getAssignmentQuestions();
      // if (!this.assignmentQnChkBox) {
      //   this.commonQuestionList = this.allManualQnsList;
      //   // this.commonQuestionList =
      //   this.commonQuestionClusterList = this.commonQuestionList;
      //   this.diffLevelFilterByClusterId = this.commonQuestionClusterList;
      //   this.commonAssignmentKscQList = this.commonQuestionClusterList;
      //   // this.isAssignmentQuestions = true;
      //   this.getSelectedEntityId(this.selectedKscClusterIds);
      // } else {
      //   // const commonQuestionList = this.commonQuestionClusterList.filter((item) => item.QuestionCategoryId === 3);
      //   this.commonQuestionList = this.allAssignmentQnsList;
      //   this.commonQuestionClusterList = this.commonQuestionList;
      //   this.diffLevelFilterByClusterId = this.commonQuestionClusterList;
      //   this.commonAssignmentKscQList = this.commonQuestionClusterList;

      //   // this.diffLevelFilterByClusterId = this.commonQuestionList;
      //   // this.commonQuestionList = this.commonQuestionList;
      // }
      this.isAssignmentQuestions = true;
    },

    getSelectedEntityId(selectedentityId) {
      this.selctedClusterList = selectedentityId;
      this.selectedCommonQuestionCluster = selectedentityId;
      this.commonQuestionList = this.commonAssignmentKscQList.filter((item1) =>
        this.selectedCommonQuestionCluster.some((item2) => item2 === item1.KSCClusterId),
      );
      if (this.commonQuestionList.length === 0) {
        this.errorMsg = "No questions available for the selected filters.";
      } else {
        this.errorMsg = "";
      }
      // this.commonQuestionList = this.commonQuestionClusterList.filter((item1) => this.selectedCommonQuestionCluster.some((item2) => item2 === item1.KSCClusterId));
      this.diffLevelFilterByClusterId = this.commonAssignmentKscQList.filter((item1) =>
        this.selectedCommonQuestionCluster.some((item2) => item2 === item1.KSCClusterId),
      );
    },
    getSelectedQuestionsBySubject(selectedentityId) {
      this.selectedSubjectIdModal = selectedentityId;
      this.selectedChapterIdModal = this.selectedChapter.filter(
        (a) => a.SubjectId === this.selectedSubjectIdModal,
      )[0].CourseChapterId;
      this.getAssignmentQuestions();
      if (this.commonQuestionList.length === 0) {
        this.errorMsg = "No questions available for the selected filters.";
      } else {
        this.errorMsg = "";
      }
    },
    getSelectedQuestionsByChapter(selectedentityId) {
      this.selectedChapterIdModal = selectedentityId;
      this.getAssignmentQuestions();
      if (this.commonQuestionList.length === 0) {
        this.errorMsg = "No questions available for the selected filters.";
      } else {
        this.errorMsg = "";
      }
    },
    getCommonQDiffLevel(diffleveldata) {
      this.commonQsDifficultyList = diffleveldata;
      this.democommonQsDifficultyList = diffleveldata;
      this.commonQuestionList = this.diffLevelFilterByClusterId.filter((item1) =>
        this.commonQsDifficultyList.some(
          (item2) => item2.toLowerCase() === item1.DifficultyGroup.toLowerCase(),
        ),
      );
      if (this.commonQuestionList.length === 0) {
        this.errorMsg = "No questions available for the selected filters.";
      } else {
        this.errorMsg = "";
      }
    },
    selectQns(questionData) {
      if (this.selectedCommonQuestions.includes(questionData.QuestionId)) {
        const index = this.selectedCommonQuestions.indexOf(questionData.QuestionId);
        if (index > -1) {
          this.selectedCommonQuestions.splice(index, 1);
        }
      } else {
        this.selectedCommonQuestions.push(questionData.QuestionId);
      }
    },

    OpenUploadAssignment() {
      this.openUploadAssignmentModel = true;
    },
    OpenAutoAssignment() {
      this.isFileUploaded = false;
      this.openAutoAssignmentModel = true;
    },

    onUploadFile(e) {
      this.file = e.target.files || e.dataTransfer.files;
      this.fileType = this.file.Type;
      if (this.uploadedFileName) {
        if (this.uploadedFileName !== e.target.files[0].name) {
          this.uploadedFileName = "";
          this.isFileUploaded = false;
          this.uploadedFileName = this.file[0].name;
        }
      } else {
        this.uploadedFileName = this.file[0].name;
      }
    },
    createStandardAssignment() {
      this.fileField1 = document.getElementById("file1");
      if (this.fileField1.files.length > 0) {
        this.isFileUploaded = true;
      }
      const chapterId = this.chapterList.filter((a) => a.CourseChapterId === this.selectedChapter);
      if (
        this.fileField1.files.length === 0 ||
        this.fileField1.files[0].type !== "application/pdf"
      ) {
        this.fileValidation = true;
        return;
      }
      this.fileValidation = false;
      if (this.selectedSubject === null) {
        this.subjectValidation = true;
        return;
      }
      this.subjectValidation = false;
      if (chapterId.length === 0 || this.topidId[0].TopicId === null) {
        this.chapterValidation = true;
      }
      this.chapterValidation = false;
      const formData = new FormData();
      formData.append("file", this.fileField1.files[0]);
      formData.append("SubjectId", this.selectedSubject);
      formData.append("TopicId", this.topidId[0].TopicId);
      formData.append("ChapterId", chapterId[0].ChapterId);
      formData.append("AssignmentTypeId", this.selectedAssignmentType);
      this.$store.dispatch("showLoader", true);
      MobileAPI.createStandardAssignment(formData, (response) => {
        this.pdfassignmentId = response.data.PdfAssignmentId;
        this.openUploadAssignmentModel = false;
        this.$store.dispatch("showLoader", false);
      });
    },
    cancelPublish() {
      this.openUploadAssignmentModel = false;
      this.isFileUploaded = false;
      this.uploadedFileName = "";
      this.file = "";
      this.pdfassignmentId = 0;
    },

    // create assignment
    CreateAssignment() {
      if (this.selectedCommonQuestions.length === 0 && this.selectedAssignmentType === 1) {
        this.commonAssiValidation = true;
        return;
      }
      this.commonAssiValidation = false;

      if (
        (this.pdfassignmentId === 0 && this.selectedAssignmentType === 2) ||
        (this.pdfassignmentId === null && this.selectedAssignmentType === 2)
      ) {
        this.fileValidation = true;
        return;
      }
      this.fileValidation = false;
      if (this.selectedAssignmentType === 2) {
        const user = this.$store.getters.user;
        const newData = this.selectedKscClusterIds.map((e) => ({
          ChapterId: e.CourseChapterId,
          ClusterId: e.KSCClusterId,
        }));

        const data = {
          CourseId: this.selectedCourse,
          TopicId: null,
          BatchIdsCsv: this.selectedBatchIds.map((a) => a.Id).join(","),
          AssignmentTypeId: 2,
          FacultyId: user.UserId,
          SubjectId: null,
          PdfAssignmentId: this.pdfassignmentId,
          ClassId: null,
          QuestionType: this.selectedQuestionTypeId,
          ChapterClusterKsc: newData,
          Title: this.assignmentTitle,
        };
        this.$store.dispatch("showLoader", true);
        MobileAPI.addAssignment(data, (response) => {
          this.addedAssignment = response.data;
          if (response.responseCode === 200) {
            toast.success("Assignment Created Successfully", {
              timeout: 2500,
            });
            window.location.reload();
            this.$store.dispatch("showLoader", false);
          }
        });
      } else if (this.selectedAssignmentType === 1) {
        if (this.selectedCommonQuestions.length === 0) {
          this.commonAssiValidation = true;
          return;
        }
        this.commonAssiValidation = false;
        const user = this.$store.getters.user;
        const newData = this.selectedKscClusterIds.map((e) => ({
          ChapterId: e.CourseChapterId,
          ClusterId: e.KSCClusterId,
        }));
        const data = {
          CourseId: this.selectedCourse,
          TopicId: null,
          BatchIdsCsv: this.selectedBatchIds.map((a) => a.Id).join(","),
          AssignmentTypeId: 1,
          FacultyId: user.UserId,
          SubjectId: null,
          QuestionIdCsv: this.selectedCommonQuestions,
          QuestionType: this.selectedQuestionTypeId,
          ClassId: null,
          ChapterClusterKsc: newData,
          Title: this.assignmentTitle,
        };
        this.$store.dispatch("showLoader", true);
        MobileAPI.addAssignment(data, (response) => {
          this.addedAssignment = response.data;
          if (response.responseCode === 200) {
            toast.success("Assignment Created Successfully", {
              timeout: 2500,
            });
            this.$router.push({
              name: "AssignmentListing",
            });
            this.$store.dispatch("showLoader", false);
          }
        });
      } else if (this.selectedAssignmentType === 3) {
        const user = this.$store.getters.user;
        const newData = this.selectedKscClusterIds.map((e) => ({
          ChapterId: this.selectedChapter,
          ClusterId: e,
        }));
        const data = {
          CourseId: this.selectedCourse,
          TopicId: this.topidId[0].TopicId,
          BatchIdsCsv: this.selectedBatchIds.map((a) => a.Id).join(","),
          AssignmentTypeId: 3,
          FacultyId: user.UserId,
          SubjectId: this.selectedSubject,
          QuestionIdCsv: this.selectedCommonQuestions,
          ClassId: this.selectedClass,
          ChapterClusterKsc: newData,
          Title: this.assignmentTitle,
        };
        this.$store.dispatch("showLoader", true);
        MobileAPI.addAssignment(data, (response) => {
          this.addedAssignment = response.data;
          if (response.responseCode === 200) {
            toast.success("Assignment Created Successfully", {
              timeout: 2500,
            });
            this.$router.push({
              name: "AssignmentListing",
            });
            this.$store.dispatch("showLoader", false);
          }
        });
      }
      this.selectedKSCes = [];
      this.selectedCourse = null;
      this.selectedCourseName = "";
      this.selectedBatchIds = null;
      this.selectedSubject = null;
      this.selectedSubjectName = "";
      this.selectedChapter = null;
      this.selectedChapterName = "";
      this.selectedClass = null;
      this.selectedClassName = "";
      this.selectedKscCluster = null;
      this.selectedKscClusterName = "";
      this.selectedAssignmentType = null;
      this.selectedAssignmentTypeName = "";
      this.selectedKscClusterIds = [];
      this.selectedKscClusterList = [];
      this.selectedCommonQuestions = [];
    },

    // navigation
    viewCourse() {
      this.selectedSection = 1;
      this.searchValue = "";
      this.isMobileNavigationVisible = false;
    },
    viewBatch() {
      if (this.selectedKscClusterIds.length === 0) {
        toast.error("Please select a cluster first.", {
          timeout: 2500,
        });
        return;
      }
      this.selectedSection = 7;
      this.searchValue = "";
      this.isMobileNavigationVisible = false;
    },
    viewClass() {
      if (!this.selectedCourse) {
        toast.error("Please select a course first.", {
          timeout: 2500,
        });
        return;
      }
      this.selectedSection = 2;
      this.searchValue = "";
      this.isMobileNavigationVisible = false;
    },
    viewSubject() {
      if (!this.selectedClass) {
        toast.error("Please select a class first.", {
          timeout: 2500,
        });
        return;
      }
      this.selectedSection = 3;
      this.searchValue = "";
      this.isMobileNavigationVisible = false;
    },
    viewChapter() {
      if (!this.selectedSubject) {
        toast.error("Please select a subject first.", {
          timeout: 2500,
        });
        return;
      }
      this.selectedSection = 4;
      this.searchValue = "";
      this.isMobileNavigationVisible = false;
    },
    viewCluster() {
      if (!this.selectedChapter) {
        toast.error("Please select a chapter first.", {
          timeout: 2500,
        });
        return;
      }
      this.selectedSection = 5;
      this.searchValue = "";
      this.isMobileNavigationVisible = false;
    },
    viewQuestionType() {
      if (!this.selectedChapter) {
        toast.error("Please select a chapter first.", {
          timeout: 2500,
        });
        return;
      }
      this.selectedSection = 6;
      this.searchValue = "";
      this.isMobileNavigationVisible = false;
    },
    viewAssignmentType() {
      if (this.selectedBatchIds.length === 0) {
        toast.error("Please select a KSC batch first.", {
          timeout: 2500,
        });
        return;
      }
      this.selectedSection = 8;
      this.searchValue = "";
      this.isMobileNavigationVisible = false;
    },
    nextSection() {
      this.selectedSection += 1;
      this.searchValue = "";
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");

* {
  box-sizing: border-box !important;
  font-family: "Open Sans", sans-serif;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

#test-input {
  padding-right: 12px;
  max-width: 300px;
  border-bottom: 1px solid #e0e4f0 !important;
}

.widgets-icon {
  display: none;
}

.pd-20 {
  padding: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.blk-text {
  color: #404458 !important;
}

.blue-font {
  color: #3751ff;
}

.blue-bg {
  background-color: #3751ff;
}

.silver-bg {
  background-color: silver;
}

.dark-font {
  color: #333;
}

.black-font {
  color: #000;
}

.white-font {
  color: #fff;
}

.margin-0 {
  margin: 0 !important;
}

.padding-0 {
  padding: 0 !important;
}

.height-100 {
  height: 100%;
}

.height-100vh {
  height: 100vh;
}

.course-btn-container {
  max-height: 300px;
  overflow-y: auto;
}

.title {
  background-color: #fff;
  color: #3751ff;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 5px;
  border-bottom: 1px solid #e0e4f0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 5px;
}

.main-card {
  min-height: calc(100vh - 100px);
  height: calc(100vh - 100px);
  overflow: hidden;
  background-color: #f7f8fd;
}

.create-assignment-btn {
  background: #37841c;
  padding: 8px 15px;
  border-radius: 5px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.ques-btn {
  background: #3751ff;
  padding: 5px 10px;
  border-radius: 5px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.modal-btns {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.modal-btn {
  width: 100px;
  border: none;
  padding: 10px 0;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

/* styles from courseSelectionComponent  */

input[type="text"]:not(.browser-default):focus:not([readonly]) {
  box-shadow: none;
  border-bottom: none;
  height: 39px;
}

input[type="text"]:not(.browser-default):focus:not([readonly]) {
  border-bottom: none !important;
  height: 39px;
}

input[type="text"]:not(.browser-default) {
  padding-right: 41px;
  max-width: 200px;
  width: 100%;
  border-bottom: none !important;
  height: 39px !important;
  color: black !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding-left: 12px !important;
  /* margin: 0px !important; */
}

.content-selection-button {
  width: 90%;
  height: 50px;
  background: #f7f8fd;
  border-radius: 4px;
  margin-top: 10px;
  /* margin-right: 24px; */
  padding-top: 8px;
  padding-left: 15px;
  position: relative;
  border: none;
  cursor: pointer;
}

.content-selection-button.active {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #3751ff;
}

.content-selection-button.active::after {
  position: absolute;
  width: 3px;
  height: 50px;
  content: "";
  background: #3751ff;
  right: 0;
  top: 0;
  border-radius: 0 2px 2px 0;
}

.content-selection-button.active::before {
  position: absolute;
  width: 4px;
  height: 4px;
  content: "";
  background: #3751ff;
  right: -2px;
  top: 23px;
  margin-left: -4px;
  -webkit-backdrop-filter: #3751ff;
  backdrop-filter: #3751ff;
  transform: rotate(45deg);
  background: #3751ff;
}

.instruction-line {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  color: #333333;
  margin-bottom: 13px;
  margin-top: 0;
}

.content-search {
  margin-left: 10px;
  margin-right: 10px;
  height: 40px;
  border: 1px solid #e0e4f0;
  background: white;
  border-radius: 4px;
  margin-top: 20px;
  justify-content: center;
  align-content: center;
  position: relative;
  border: none;
}

.course-button {
  background: #ffffff;
  border: 1px solid #e0e4f0;
  border-radius: 18px;
  padding: 5px 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #333333;
  cursor: pointer;
}

.selected {
  background: #3751ff !important;
  color: #ffffff;
}

.deactive {
  background: grey !important;
  color: #ffffff !important;
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.next-button {
  width: 100px;
  height: 28px;
  background: #37841c;
  border-radius: 5px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.select-all {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e4f0;
  height: 35px;
  border-radius: 4px;
  background: #ffffff;
  width: 100px;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: unset !important;
  opacity: 1 !important;
  pointer-events: all !important;
  height: 50px;
  width: 18px;
  margin-right: 10px;
}

.assignment-modal-body {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: #fff;
  transition: all 0.8s ease-in-out;
  /* overflow-x: scroll; */
  /* overflow-y: scroll; */
}

.filterSection {
  width: 265px;
  border-radius: 4px;
  border: 1px solid #e0e4f0;
  /* padding-left: 15px; */
  padding-top: 5px;
  padding-bottom: 35px;
  overflow-x: auto;
  /* height: 525px; */
}

.scroll-verticle-bar {
  max-height: calc(100vh - 100px);
  min-height: 500px;
  padding-bottom: 80px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 41px;
  height: 20px;
  margin: 0 10px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f3f3f3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0px;
  bottom: 0px;
  background-color: #4787f3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #f3f3f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #f3f3f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(22px);
  background-color: #fcbc5c;
}

.slider.round {
  border-radius: 34px;
}

.increaseHeight {
  height: 105px;
}

.slider.round:before {
  border-radius: 50%;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.023);
  background-color: #f9f9f9;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 5px;
  display: block;
  background-color: #f9f9f9;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #e5e5e5;
}

@media only screen and (max-width: 1220px) {
  .body-for-mobile {
    /* max-height: 100%; */
    /* padding-bottom: 100px; */
    overflow-y: auto;
    position: relative;
  }

  .right-section-mobile {
    /* overflow-y: auto; */
  }
}

@media only screen and (max-width: 1000px) {
  .main-card {
    height: 100% !important;
  }

  .nav-section-mobile {
    /* flex-wrap: wrap;
    flex-direction: row;
    gap: 20px; */
    position: absolute;
    top: 0;
    left: -250px;
    z-index: 10;
    background-color: #fff;
    padding: 10px;
    transition: all 0.3s ease-in-out;
    min-height: 100% !important;
    /* overflow-y: hidden; */
  }

  .nav-section-mobile.active-nav {
    left: 0 !important;
  }

  .right-section-mobile {
    padding-top: 40px;
    min-height: 80vh;
  }

  .widgets-icon {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #3751ff;
    border-radius: 0 50% 50% 0;
    width: 35px;
    height: 35px;
    padding: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .close-nav-icon {
    position: absolute;
    top: -20px;
    right: -45px;
    background-color: #3751ff;
    border-radius: 0 50% 50% 0;
    width: 35px;
    height: 35px;
    padding: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .content-selection-button {
    width: 230px;
  }

  .assignment-modal-body {
    overflow: auto;
  }
}

.modalSlide-leave-active {
  transition: all 0.8s ease-in-out;
}

.modalSlide-enter {
  transform: translateX(100%);
}

.modalSlide-enter-active {
  transition: all 0.1s ease-in-out;
  transform: translateX(100%);
}

.modalSlide-leave-to {
  transform: translateX(100%);
}

.warning-section-modal {
  width: 400px;
  /* height: 356px; */
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirm-btn {
  width: 84px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #37841c;
  border-radius: 4px;
  background: #ffffff;
  color: #37841c;
  cursor: pointer;
  margin-right: 10px;
}

.confirm-btn:hover {
  background: #37841c !important;
  color: #ffffff !important;
}

.cancel-btn {
  width: 84px;
  padding-right: 5px;
  padding-left: 5px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #ff7675;
  border-radius: 4px;
  color: #ff7675;
  cursor: pointer;
  margin-left: 10px;
}
</style>
